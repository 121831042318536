//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LoadMore",
  props: {
    enableLoadMore: {
      type: Boolean,
      default: true,
    },
    onLoadMore: {
      type: Function,
      default: undefined,
      require: false,
    },
  },
  data() {
    return {
      loadMoreText: "上拉加载更多",
      startX: 0,
      startY: 0,
      isLoading: false,
    };
  },
  methods: {
    touchStart(e) {
      this.startY = e.targetTouches[0].pageY;
      this.startX = e.targetTouches[0].pageX;
    },
    scrollToEnd() {
      let scrollHeight = this.$el.scrollHeight;
      let clientHeight = this.$el.clientHeight;
      let scrollTop = this.$el.scrollTop;

      if (scrollTop + clientHeight >= scrollHeight || this.enableLoadMore) {
        this.doLoadMore();
      }
    },
    touchEnd(e) {
      if (this.isLoading) {
        return;
      }

      let endX = e.changedTouches[0].pageX,
        endY = e.changedTouches[0].pageY,
        dy = this.startY - endY,
        dx = endX - this.startX;
      if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
        return;
      }
      if (endY < this.startY) {
        this.scrollToEnd(e);
      }
    },
    doLoadMore() {
      this.isLoading = true;
      this.loadMoreText = "加载中...";
      this.onLoadMore(this.loadDone);
    },
    loadDone() {
      this.isLoading = false;
      this.loadMoreText = "上拉加载更多";
    },
  },
};
