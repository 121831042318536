//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { record } from '../../common/api'
import luxiang from './components/luxiang.vue'
import myScroll from './components/myScroll.vue'
import Utils from '../../util/index'
export default {
  components: {
    myScroll,
    luxiang,
  },
  data() {
    return {
      feedList: [],
      page: 1,
      days: 5,
      enableLoadMore: true,
      none: false,
      isLoading: false,
      lushow: false,
      video_url: '',
    }
  },
  created() {
    this.recordJl()
  },
  mounted() {},
  methods: {
    close() {
      this.$router.go(-1)
      Utils.$emit('getToken')
    },
    // 关闭录屏
    closeLu(val) {
      this.lushow = val
    },
    lookLu(url) {
      this.video_url = url
      this.lushow = true
    },
    //喂养记录
    async recordJl() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        page: this.page,
        days: this.days,
      }
      let res = await record(data)
      if (res.data.code == 200) {
        if (this.page == 1) {
          this.feedList = res.data.data
        } else {
          this.feedList = [...this.feedList, ...res.data.data]
        }
      }
      if (res.data.code == '4004') {
        this.enableLoadMore = false
      }
    },
    // 上拉加载
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) return
        this.page = this.page + 1
        this.recordJl()
        done()
      }, 200)
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false
        this.page = 1
        this.recordJl()
        this.enableLoadMore = true
      }, 1000)
    },
  },
}
